@charset "UTF-8";
/* table contents
=====================/
  /* You can add global styles to this file, and also import other style files
  # General elements
      - @Imports
      - Fonts
      - body-layout
      - colors
      - Handle Scroll style
      - shorthand classes
  # Links
  # Buttons
  # Forms
  # Images
  # Lists
    - links-list
    - social-list
    - site-navigation
  # Sections
  # widgets
  # empty-div and empty-item style
  # gallery
  # owlNav-section
  # Modal Side-nav only
  # toaster and Tooltip overwrite
  # rating
  # Loading Spinner styles
  # Start Profile pages style (Common styles)


  # Overwrite Bootstrap Style in RTL

*/
/* General elements
======================*/
.submit-btn {
  position: relative;
  text-align: center;
  margin: 15px auto;
}
.submit-btn .btn-continue {
  background-color: #0099ff;
  padding: 8px 30px;
  font-size: 1.8rem;
  color: #FFF;
  border-radius: 8px;
  text-transform: capitalize;
  box-shadow: none;
  transition: all 0.2s linear;
}
.submit-btn .btn-continue .icon-arrow {
  margin-left: 10px;
  position: relative;
  left: 0;
  width: 10px;
  transition: all 0.2s ease-in;
}
[dir=rtl] .submit-btn .btn-continue .icon-arrow {
  margin-left: auto;
  margin-right: 10px;
  left: auto;
  right: 0;
  transform: rotate(180deg);
}
.submit-btn .btn-continue:enabled:hover {
  opacity: 1;
}
.submit-btn .btn-continue:enabled:hover .icon-arrow {
  left: 7px;
}
[dir=rtl] .submit-btn .btn-continue:enabled:hover .icon-arrow {
  left: auto;
  right: 7px;
}
.submit-btn .btn-continue:disabled:hover {
  cursor: not-allowed;
}
.submit-btn .tooltip--activated {
  width: 420px;
  font-size: 14px;
  position: absolute;
  top: -35px;
  opacity: 0;
  background-color: #f8d7da;
  padding: 5px 30px;
  left: 50%;
  margin-left: -210px;
  transition: all 0.3s ease-in;
}
.submit-btn:hover .tooltip--activated {
  opacity: 1;
}

@media (max-width: 556px) {
  .submit-btn .tooltip--activated {
    display: none;
  }
}
.text-alert {
  padding: 5px;
  animation: moveToRight;
  animation-duration: 0.5s;
  margin-top: 5px;
  font-size: 14px;
}

@keyframes moveToRight {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}
.lds-ring {
  display: block;
  position: absolute;
  width: 55px;
  height: 55px;
  transform: translate(-50%, -50%);
  top: 40%;
  left: 50%;
  background: #fff;
  box-shadow: 0px 1px 10px #ccc;
  border-radius: 50%;
  z-index: 2;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 7px;
  border: 6px solid #0099ff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0099ff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo ExtraLight"), local("Cairo-ExtraLight"), url("fonts/Cairo/Cairo-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo Light"), local("Cairo-Light"), url("fonts/Cairo/Cairo-Light.ttf");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo Regular"), local("Cairo-Regular"), url("fonts/Cairo/Cairo-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo SemiBold"), local("Cairo-SemiBold"), url("fonts/Cairo/Cairo-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo Bold"), local("Cairo-Bold"), url("fonts/Cairo/Cairo-Bold.ttf");
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo Black"), local("Cairo-Black"), url("fonts/Cairo/Cairo-Black.ttf");
  font-weight: 900;
  font-display: swap;
}
/*body-layout*/
*, *:hover, *:focus {
  outline: none !important;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  overflow-x: hidden;
  background-color: var(--body-bg-color, #fff);
  font-family: var(--font-family, Cairo, sans-serif);
}
[dir=rtl] body {
  text-align: right;
  direction: rtl;
}
body .wrapper {
  position: relative;
  width: 100%;
}
body .inner-page {
  background-color: transparent;
  min-height: 45rem;
}
body h1 {
  color: var(--h1-color, #000);
  font-size: var(--h1-font-size, 20px);
}
body h2 {
  color: var(--h2-color, #000);
  font-size: var(--h2-font-size, 20px);
}
body h3 {
  color: var(--h3-color, #000);
  font-size: var(--h3-font-size, 20px);
}
body a {
  color: var(--body-link-color, #333);
  font-size: var(--body-link-font-size, 16px);
}

/* colors
=====================*/
.text-yellow {
  color: var(--main-color, #e6e6e6) !important !important;
}

.bg-gray {
  background-color: #f5f5f5 !important;
}

.bg-light-blue {
  background-color: #0099ff !important;
}

.bg-light-green {
  background-color: #20ca54 !important;
}

.bg-tomato {
  background-color: #f38432 !important;
}

.bg-red {
  background-color: #f33232 !important;
}

/* Handle Scroll style
==============================*/
::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  transition: all 0.3s ease-in-out;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  transition: all 0.3s ease-in-out;
  border-radius: 1.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--main-color, #e6e6e6) !important;
  cursor: pointer;
}

.sm-gutters {
  margin-right: -0.6rem;
  margin-left: -0.6rem;
}
.sm-gutters > .col,
.sm-gutters > [class*=col-] {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.invisible {
  visibility: hidden !important;
  display: none;
}

/* Links
==================*/
a {
  color: var(--body-link-color, #333);
  font-size: var(--body-link-font-size, 1.4rem);
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  cursor: pointer;
}

/* Buttons
==================================*/
.custom-btn {
  display: inline-block;
  position: relative;
  border-radius: 3rem;
  background-color: #FFFFFF;
  color: var(--main-color, #e6e6e6) !important;
  padding: 8px 20px;
  border: 1px solid var(--main-color, #e6e6e6) !important;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
[dir=rtl] .custom-btn {
  font-size: 1.6rem;
}
.custom-btn:enabled:hover, .custom-btn:enabled:focus, .custom-btn:enabled:active {
  text-decoration: none;
  background-color: var(--main-color, #e6e6e6) !important;
  border-color: var(--main-color, #e6e6e6) !important;
  outline: none;
  box-shadow: none;
  color: #fff;
}
.custom-btn.btn-sm {
  padding: 7px 15px;
}
.custom-btn.reversed {
  background-color: var(--main-color, #e6e6e6) !important;
  border: none;
}
.custom-btn.reversed:enabled:hover, .custom-btn.reversed:enabled:focus {
  background-color: #333;
  color: #fff;
}
.custom-btn:disabled {
  cursor: not-allowed;
  border: 1px solid var(--main-color-opacity, rgba(251, 201, 0, 0.59));
  background-color: var(--main-color-opacity, rgba(251, 201, 0, 0.59));
}

.icon-btn {
  width: 4.5rem;
  height: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
  border-radius: 100%;
  background-color: #fff;
  color: #808080;
  border: 1px solid var(--main-color, #e6e6e6) !important;
  font-weight: bold;
  cursor: pointer;
  padding: 0;
  font-size: 2rem;
  transition: all 0.3s ease-in-out;
}
.icon-btn:hover, .icon-btn.active {
  text-decoration: none;
  background-color: var(--main-color, #e6e6e6) !important;
  border-color: var(--main-color, #e6e6e6) !important;
  color: #fff;
  outline: none;
  box-shadow: none;
}

/* forms
==============================*/
.form-group {
  position: relative;
}
.form-group label {
  text-transform: capitalize;
}

.form-control {
  height: 4rem;
  font-size: 1.6rem;
  border: 1px solid #acacac;
  border-radius: 5rem;
  transition: all 0.3s ease-in-out;
}
.form-control::-webkit-input-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder {
  color: #8f8f8f;
  text-transform: capitalize;
  font-size: 1.4rem;
}
.form-control.remove-default::-webkit-inner-spin-button, .form-control.remove-default::-webkit-outer-spin-button, .form-control.remove-default, .form-control[type=date]::-webkit-inner-spin-button, .form-control[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}
.form-control.remove-default::-webkit-inner-spin-button, .form-control.remove-default::-webkit-outer-spin-button, .form-control.remove-default, .form-control[type=date] {
  -moz-appearance: textfield;
}

textarea.form-control {
  resize: none;
  height: auto;
}

/*form group with icon*/
.has-icon {
  position: relative;
}
.has-icon .form-control {
  padding-right: 1rem;
}
.has-icon .form-control:focus i {
  color: var(--main-color, #e6e6e6) !important !important;
}
.has-icon i {
  position: absolute;
  top: 0.8rem;
  right: 0.5rem;
  font-size: 1.2rem;
  color: #0099ff;
  transition: all 0.3s ease-in-out;
}
[dir=rtl] .has-icon i {
  right: auto;
  left: 0.5rem;
}
.has-icon label ~ i {
  top: 4rem;
  right: 1rem;
  font-size: 1.3rem;
}

/* Images
============================*/
img {
  max-width: 100%;
  height: auto;
}

.img-wrapper {
  position: relative;
}

.easyzoom-flyout img {
  max-width: initial;
}

.responsive-image-container {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 991.98px) {
  .container-fluid-md {
    width: 100% !important;
    max-width: none !important;
    padding: 0 !important;
  }
}
/* Lists
============================*/
/*links-list*/
ul.links-list {
  padding: 0;
  margin-bottom: 3rem;
}
ul.links-list li {
  list-style: none;
}
ul.links-list li a {
  color: #fff;
  line-height: 2.2;
  text-transform: uppercase;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
ul.links-list li a:hover {
  color: var(--main-color, #e6e6e6) !important !important;
}

/*social-list*/
ul.social-list {
  padding: 0;
}
ul.social-list li:not(.whatsApp_link) {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
ul.social-list li:not(.whatsApp_link) a {
  display: block;
  position: relative;
  width: 3.8rem;
  height: 3.8rem;
  line-height: 3.8rem;
  font-size: 2rem;
  text-align: center;
  background-color: #fff;
  color: #222;
  border-radius: 4px;
  overflow: hidden;
  transform: scale(0.9);
  box-shadow: 0 0 0 1px rgba(154, 154, 154, 0.2);
  transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
ul.social-list li:not(.whatsApp_link) a:hover {
  color: #fff !important;
  background-color: var(--main-color, #e6e6e6) !important;
}

/*site-navigation*/
.site-navigation {
  text-align: center;
}
.site-navigation .pagination {
  justify-content: center;
}
.site-navigation .pagination .page-item .page-link {
  padding: 0;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  background-color: transparent;
  color: var(--main-second-color, #ffffff);
  margin-left: 0.5rem;
  font-weight: bold;
  border-radius: 1rem;
}
.site-navigation .pagination .page-item .page-link:focus {
  box-shadow: none;
}
.site-navigation .pagination .page-item .page-link:hover {
  background-color: #e9ecef;
  color: var(--main-second-color, #ffffff);
}
.site-navigation .pagination .page-item.active .page-link {
  background-color: var(--main-color, #e6e6e6) !important;
  border-color: var(--main-color, #e6e6e6) !important;
  color: #fff;
}
.site-navigation .pagination .page-item.active .page-link:hover {
  color: var(--main-second-color, #ffffff) !important;
}
.site-navigation .pagination .page-item.pagination-prev.disabled, .site-navigation .pagination .page-item.pagination-next.disabled {
  display: none;
}
.site-navigation .pagination .page-item.pagination-prev a, .site-navigation .pagination .page-item.pagination-next a {
  position: relative;
  color: transparent !important;
}
.site-navigation .pagination .page-item.pagination-prev a:before, .site-navigation .pagination .page-item.pagination-next a:before {
  font-family: "FontAwesome", serif;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #090909;
  font-size: 2rem;
  left: 0;
}
.site-navigation .pagination .page-item.pagination-prev a::selection, .site-navigation .pagination .page-item.pagination-next a::selection {
  color: transparent !important;
}
.site-navigation .pagination .page-item.pagination-prev {
  margin-left: 0;
}
.site-navigation .pagination .page-item.pagination-prev a:before {
  content: "";
}
[dir=rtl] .site-navigation .pagination .page-item.pagination-prev a:before {
  content: "";
}
.site-navigation .pagination .page-item.pagination-next {
  margin-left: 0;
}
.site-navigation .pagination .page-item.pagination-next a:before {
  content: "";
}
[dir=rtl] .site-navigation .pagination .page-item.pagination-next a:before {
  content: "";
}

/* Sections
===================================*/
.section-settings {
  position: relative;
  min-height: 40rem;
  padding: 5rem 0 3rem 0;
}
.section-settings .section-head {
  position: relative;
  margin-bottom: 4rem;
}
.section-settings .section-head .section-title {
  position: relative;
  display: inline-block;
  color: var(--h3-color, #292929);
  font-size: var(--h3-font-size, 3rem);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.section-settings .section-head .more-btn {
  float: right;
  color: var(--body-link-color, #7a7a7a);
  font-size: var(--body-link-font-size, 1.8rem);
  font-weight: 600;
  margin-top: 1rem;
}
[dir=rtl] .section-settings .section-head .more-btn {
  float: left;
}

/* empty-div and empty-item style
============================================*/
.empty-div {
  text-align: center;
  text-transform: capitalize;
  font-size: 24px;
  background-color: #f4f4f4;
  padding: 30px;
  width: 60%;
  margin: 100px auto;
}
.empty-div p {
  margin-bottom: 0;
}
.empty-div ~ .text-center {
  margin-bottom: 3rem;
}

.empty-item {
  background-color: #fff;
  margin-bottom: 3rem;
  border-radius: 1rem;
  text-align: center;
  padding: 2rem 1rem;
  border: 1px solid var(--main-color, #e6e6e6) !important;
}
.empty-item i {
  display: block;
  font-size: 130px;
  color: var(--main-color-opacity, rgba(251, 201, 0, 0.59));
  margin-bottom: 10px;
}
.empty-item .help-text {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: bold;
}

.gallery .carousel-control-prev-icon {
  background-color: var(--main-color, #e6e6e6) !important;
  width: 53px;
  border-radius: 0 20px 20px 0;
  height: 35px;
  color: var(--main-color, #e6e6e6) !important;
  position: absolute;
  left: 0;
}
.gallery .carousel-indicators {
  display: none;
}
.gallery .carousel-control-next-icon {
  background-color: var(--main-color, #e6e6e6) !important;
  width: 53px;
  border-radius: 20px 0 0 20px;
  height: 35px;
  color: var(--main-color, #e6e6e6) !important;
  position: absolute;
  right: 0;
}
.gallery .carousel-control-prev-icon {
  background-image: url("images/prev.png");
  background-size: 15px;
}
.gallery .carousel-control-next-icon {
  background-image: url("images/next.png");
  background-size: 15px;
}

/*owlNav-section
====================*/
.custom-owl-nav .owl-carousel .owl-nav button.owl-next,
.custom-owl-nav .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 40%;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  border-radius: 50%;
  background-color: transparent;
  color: #333;
  font-size: 2.8rem;
  font-family: sans-serif;
  transition: all 0.3s ease-in-out;
}
.custom-owl-nav .owl-carousel .owl-nav button.owl-next:hover,
.custom-owl-nav .owl-carousel .owl-nav button.owl-prev:hover {
  background-color: var(--main-color, #e6e6e6) !important;
  color: #fff;
}
.custom-owl-nav .owl-carousel .owl-nav button.owl-next {
  right: -6rem;
}
[dir=rtl] .custom-owl-nav .owl-carousel .owl-nav button.owl-next {
  right: auto;
  left: -6rem;
}
.custom-owl-nav .owl-carousel .owl-nav button.owl-prev {
  left: -6rem;
}
[dir=rtl] .custom-owl-nav .owl-carousel .owl-nav button.owl-prev {
  left: auto;
  right: -6rem;
}
.custom-owl-nav .owl-theme .owl-dots .owl-dot.active span,
.custom-owl-nav .owl-theme .owl-dots .owl-dot:hover span {
  background-color: var(--main-color, #e6e6e6) !important;
}

/* Modal Side-nav only
============================*/
.w-80 {
  width: 80vw !important;
}

.modal-dialog:not(.branch-selector-modal) {
  width: 100%;
  margin: 0;
  max-width: 100%;
}
.modal-dialog:not(.branch-selector-modal) .modal-content {
  border: 0;
  border-radius: 0;
  background-color: var(--header-bg-color, #fff);
}

/*toaster and Tooltip overwrite
==============================*/
.toast-container .toast {
  position: relative;
  padding: 15px 20px 15px 50px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 320px;
  font-size: 1.4rem;
  box-shadow: none;
  background-image: none;
  opacity: 1;
}
.toast-container .toast:after {
  position: absolute;
  top: 15px;
  left: 15px;
  font-family: "FontAwesome";
  font-size: 24px;
}
.toast-container .toast:hover {
  box-shadow: none;
}
.toast-container .toast .toast-close-button {
  transition: all 0.3s ease-in-out;
  color: #155724;
}
.toast-container .toast.toast-success {
  color: #155724;
  background-color: #53e6bd;
  border-color: #c3e6cb;
}
.toast-container .toast.toast-success:after {
  content: "";
}
.toast-container .toast.toast-error {
  color: #721c24;
  background-color: #f2b3b8;
  border-color: #f5c6cb;
}
.toast-container .toast.toast-error:after {
  content: "";
}

/*tooltips*/
.tooltip {
  font-size: 1.4rem;
}

/*rating*/
.bs-rating-star {
  font-size: 0;
}
.bs-rating-star::after {
  color: grey;
  content: "★";
  font-size: 25px;
}
.bs-rating-star.active:after {
  color: var(--main-color, #e6e6e6) !important;
}
.bs-rating-star .fa-star {
  color: var(--main-color, #e6e6e6) !important;
}
.bs-rating-star .fa-star.empty {
  color: gray;
}

.product-rating {
  font-size: 0;
}

/* Loading Spinner styles
===========================*/
.collection__spinner {
  position: relative;
  min-height: 50vh;
  width: 100%;
}
.collection__spinner .lds-ellipsis {
  position: absolute;
  width: 64px;
  height: 64px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.collection__spinner .lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.collection__spinner .lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.collection__spinner .lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.collection__spinner .lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.collection__spinner .lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

/* Start Profile pages style (Common styles)
=============================================*/
.profile-page-content .title h3 {
  font-weight: bold;
  color: var(--h3-color, #000);
  font-size: var(--h3-font-size, 20px);
  margin-bottom: 15px;
  text-transform: capitalize;
}
.profile-page-content .title h3 small {
  font-size: 16px;
  color: #6b6b6b;
  margin-top: 10px;
  display: inline-block;
}
.profile-page-content .box-item {
  position: relative;
  background-color: #fff;
  border: 1px solid #acacac;
  border-radius: 20px;
  margin-bottom: 30px;
}
.profile-page-content .box-item .box-item-head {
  padding: 15px;
  font-weight: bold;
  font-size: 24px;
  color: #000;
  border-bottom: 1px solid #acacac;
  text-transform: capitalize;
}
.profile-page-content .box-item .box-item-content {
  padding: 30px;
}
@media (max-width: 991px) {
  .profile-page-content .box-item .box-item-content b {
    margin-bottom: 25px;
  }
}
.profile-page-content .box-item .box-item-content .inline-form .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.profile-page-content .box-item .box-item-content .inline-form .form-group label {
  font-weight: bold;
  text-transform: capitalize;
  width: 30%;
  font-size: 1.4rem;
}
.profile-page-content .box-item .box-item-content .inline-form .form-group .form-control {
  border: 1px solid #acacac;
  height: 50px;
  border-radius: 20px;
  box-shadow: none;
  width: 70%;
}
.profile-page-content .box-item .box-item-content .inline-form .form-group .form-control:focus {
  border-color: var(--main-color, #e6e6e6) !important;
}
.profile-page-content .box-item .box-item-content .inline-form .form-group.custom-checkbox {
  padding-left: 30%;
}
[dir=rtl] .profile-page-content .box-item .box-item-content .inline-form .form-group.custom-checkbox {
  padding-left: 0;
  padding-right: 30%;
}
@media (max-width: 578px) {
  .profile-page-content .box-item .box-item-content .inline-form .form-group.custom-checkbox {
    padding-left: 0;
  }
  [dir=rtl] .profile-page-content .box-item .box-item-content .inline-form .form-group.custom-checkbox {
    padding-right: 0;
  }
}
.profile-page-content .box-item .box-item-content .inline-form .form-group.custom-checkbox label {
  width: auto;
}
.profile-page-content .box-item .box-item-content .inline-form .form-group.has-icon {
  position: relative;
}
.profile-page-content .box-item .box-item-content .inline-form .form-group.has-icon span {
  position: absolute;
  top: 11px;
  right: 15px;
  color: #939393;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
[dir=rtl] .profile-page-content .box-item .box-item-content .inline-form .form-group.has-icon span {
  left: 15px;
  right: auto;
}
.profile-page-content .box-item .box-item-content .inline-form .form-group.has-icon .form-control:focus ~ span {
  color: var(--main-color, #e6e6e6) !important;
}
@media all and (max-width: 576.98px) {
  .profile-page-content .box-item .box-item-content .inline-form .form-group {
    display: block;
  }
  .profile-page-content .box-item .box-item-content .inline-form .form-group label,
.profile-page-content .box-item .box-item-content .inline-form .form-group .form-control {
    width: 100%;
  }
  .profile-page-content .box-item .box-item-content .inline-form .form-group.has-icon span {
    top: 42px;
  }
  .profile-page-content .box-item .box-item-content .inline-form .form-group.custom-checkbox {
    padding: 0;
  }
}
.profile-page-content .box-item.order .box-item-head {
  overflow: hidden;
}
.profile-page-content .box-item.order .box-item-head .status {
  float: right;
  color: #0099ff;
  font-weight: 600;
  font-size: 18px;
}
[dir=rtl] .profile-page-content .box-item.order .box-item-head .status {
  float: left;
}
.profile-page-content .box-item.order .box-item-content .cool {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.profile-page-content .box-item.order .box-item-content .cool > span {
  text-transform: capitalize;
}
.profile-page-content .box-item.order .box-item-content .cool > span span {
  font-weight: 600;
}
.profile-page-content .box-item.order .box-item-content .cool > span.total span {
  color: #2aa524;
}
.profile-page-content .box-item.order .box-item-content .cool .custom-btn {
  margin: 0 5px;
}
.profile-page-content .validation-text {
  font-size: 14px;
  color: #bd2130;
  position: relative;
  top: -10px;
  margin-left: 30%;
  margin-top: 15px;
}
[dir=rtl] .profile-page-content .validation-text {
  margin-left: auto;
  margin-right: 30%;
}
@media all and (max-width: 576.98px) {
  .profile-page-content .validation-text {
    margin-left: 0;
  }
  [dir=rtl] .profile-page-content .validation-text {
    margin-right: 0;
  }
}
.profile-page-content .custom-checkbox label {
  display: inline-block;
  position: relative;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  padding-left: 25px;
  margin-bottom: 5px;
  color: #030303;
  /* Create a custom input */
  /* Hide the browser's default radio button */
}
[dir=rtl] .profile-page-content .custom-checkbox label {
  padding-left: 0;
  padding-right: 25px;
}
.profile-page-content .custom-checkbox label > span {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #cdcece;
  background-color: #ffffff;
  border-radius: 2px;
}
[dir=rtl] .profile-page-content .custom-checkbox label > span {
  left: initial;
  right: 0;
}
.profile-page-content .custom-checkbox label > span i {
  position: absolute;
  color: #030303;
  font-size: 13px;
  display: none;
}
.profile-page-content .custom-checkbox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  /* When the radio button is checked, add a blue background */
}
.profile-page-content .custom-checkbox label input:checked ~ span {
  font-weight: bold;
  border-color: #0099ff;
  background-color: #0099ff;
}
.profile-page-content .custom-checkbox label input:checked ~ span i {
  display: block;
  color: #fff;
}

/* Overwrite Bootstrap Style in RTL
=====================================*/
[dir=rtl] .mr-auto, [dir=rtl] .mx-auto {
  margin-right: 0 !important;
  margin-left: auto;
}
[dir=rtl] .ml-auto, [dir=rtl] .mx-auto {
  margin-left: 0 !important;
  margin-right: auto;
}
[dir=rtl] .mr-5, [dir=rtl] .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}
[dir=rtl] .navbar-nav {
  padding-right: 0;
}
[dir=rtl] .float-right {
  float: left !important;
}
[dir=rtl] .float-left {
  float: right !important;
}
[dir=rtl] .dropdown-menu {
  text-align: right;
  left: auto;
  right: 0;
}
[dir=rtl] .text-right {
  text-align: left !important;
}
@media (min-width: 768px) {
  [dir=rtl] .offset-md-2 {
    margin-right: 16.66666667%;
    margin-left: auto;
  }
  [dir=rtl] .offset-md-3 {
    margin-right: 25%;
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  [dir=rtl] .offset-lg-3 {
    margin-left: auto;
    margin-right: 25%;
  }
}

:root {
  --main-color: orange;
}

body {
  --main-color-opacity: #999;
  --main-second-color: #090909;
  --second-color: #0099ff;
  --border-color: #acacac;
  --background-color: #f5f5f5;
  --input-background-color: #ebebeb;
  --hr-color: #adadad;
  --top-header-background-color: #666666;
  --forth-color: #01427f;
  --greenColor: #368840;
  --lightGray: rgba(0, 0, 0, 0.02);
  --borderColor: rgba(0, 0, 0, 0.05);
  --lightGrayDark: rgba(0, 0, 0, 0.1);
  --textColor: #808080;
  background-color: var(--body-bg-color, #fff);
  font-family: var(--font-family, Cairo, sans-serif);
}
body h1 {
  color: var(--h1-color, #000);
  font-size: var(--h1-font-size, 20px);
}
body h2 {
  color: var(--h2-color, #000);
  font-size: var(--h2-font-size, 20px);
}
body h3 {
  color: var(--h3-color, #000);
  font-size: var(--h3-font-size, 20px);
}
body a {
  color: var(--body-link-color, #333);
  font-size: var(--body-link-font-size, 16px);
}
body .custom-btn:disabled {
  color: #fff !important;
  background-color: var(--main-color-opacity, rgba(251, 201, 0, 0.59));
  border: 1px solid var(--main-color-opacity, rgba(251, 201, 0, 0.59)) !important;
}

.overflow {
  overflow: hidden;
}

.custom-btn.reversed {
  color: #fff !important;
}
.custom-btn:hover {
  color: #fff !important;
}

.bg-gray {
  background-color: gray !important;
}

main#content {
  min-height: 45vh;
}

*:focus {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: 0;
}

.form-control:focus {
  outline: none;
  box-shadow: none !important;
  border-color: #acacac;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

.custom-owl-nav .owl-carousel .owl-nav button.owl-next,
.custom-owl-nav .owl-carousel .owl-nav button.owl-prev {
  font-size: 24px;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
}
.custom-owl-nav .owl-carousel .owl-nav button.owl-next span,
.custom-owl-nav .owl-carousel .owl-nav button.owl-prev span {
  margin-bottom: 2px;
}

.banner-area .banner-container {
  padding-left: 20px;
  padding-right: 20px;
}

.banner-area .banner-image > img {
  transition: all 0.3s ease-out 0s;
  width: 100%;
  border-radius: 10px;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.custom-padding-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.banner-image-wrap {
  position: relative;
  overflow: hidden;
}

.banner-image-wrap:hover .banner-hover-text {
  bottom: 20px;
}

.banner-image-wrap:hover .banner-hover-text > span {
  opacity: 1;
  visibility: visible;
}

.banner-image > img {
  transition: all 0.3s ease-out 0s;
  width: 100%;
}

.banner-image:hover img {
  transform: scale(1.05);
}

.banner-image {
  display: block;
  overflow: hidden;
  position: relative;
}

.banner-area .banner-image > img {
  transition: all 0.3s ease-out 0s;
  width: 100%;
  border-radius: 10px;
}

.banner-image::after {
  background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.3s ease-out 0s;
  width: 100%;
  z-index: 2;
}

.banner-image:hover::after {
  opacity: 1;
}

.banner-image-wrap {
  position: relative;
  overflow: hidden;
}

.banner-image-wrap:hover .banner-hover-text {
  bottom: 20px;
}

.banner-image-wrap:hover .banner-hover-text > span {
  opacity: 1;
  visibility: visible;
}

.banner-hover-text {
  bottom: -5px;
  left: 20px;
  position: absolute;
  z-index: 3;
  transition: 0.3s;
}

.banner-hover-text > h5 {
  color: #23232c;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 6px;
}

.banner-hover-text > span {
  color: #666;
  font-size: 12px;
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
}

.banner-image-wrap:hover .banner-hover-text {
  bottom: 20px;
}

.banner-image-wrap:hover .banner-hover-text > span {
  opacity: 1;
  visibility: visible;
}

.modal {
  top: 0;
  z-index: 999999999;
}

.quickview_product-img {
  max-height: 300px !important;
}

@media screen and (max-width: 767px) {
  .banner-area .banner-image-wrap a {
    margin: 0;
  }

  .header {
    position: unset !important;
  }

  main#content {
    min-height: 45vh;
  }

  .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .banner-area .col-lg-6 {
    margin-bottom: 15px;
  }

  .banner-area .banner-image-wrap a {
    margin: 0;
  }

  .banner-area .row .col-lg-6:last-child {
    margin-bottom: 0;
  }

  .banner-area.first-banner .banner-image > img {
    min-height: auto;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .banner-area .row .col-lg-6:last-child .col-lg-6,
.small-product-blog-area .col-xl-6 .col-md-6,
#grid .col-xl-4,
#grid .col-xl-3 {
    width: 50%;
  }

  .banner-area .col-lg-4 {
    width: 33.3333%;
  }
}
modal-container.modal {
  height: -moz-calc(100vh - 150px) !important;
  height: -webkit-calc(100vh - 150px) !important;
  height: calc(100vh - 150px) !important;
}

modal-container .modal-dialog {
  height: 145vh !important;
}

@media screen and (max-width: 992px) {
  .owl-carousel .owl-item img {
    display: block;
  }
}
.my-custom-ng-select .ng-value-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 93px;
}
.my-custom-ng-select .ng-option-marked {
  background-color: #f5faff;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.my-custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
}
.my-custom-ng-select .ng-dropdown-panel .ng-dropdown-panel-items {
  display: block;
  height: auto;
  box-sizing: border-box;
  max-height: 135px;
  overflow-y: auto;
}

.flag {
  width: 30px;
  height: 20px;
}

.sidenav__list {
  list-style: none;
  margin: 0 auto !important;
  display: block !important;
  position: unset !important;
  float: unset !important;
}

app-base-header-item#dynamicMenu {
  display: inline-block;
}

.easyzoom-flyout img {
  position: absolute;
  top: 9px;
  left: 0;
}

html[dir=ltr] .children {
  left: 100%;
  top: 0 !important;
  right: auto;
}

/* Basic style for the dropdown menu */
.nav-item {
  position: relative;
}

.dropdown-menu {
  display: none;
  min-width: 200px;
  padding: 12px 0;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transform: translateY(-10px);
  transition: opacity 0.3s, visibility 0s linear 0.3s, transform 0.3s;
  z-index: 1000;
  padding-left: 40px !important;
}

/* Show menu on hover */
.nav-item:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 0s;
}

/* Style for individual menu items */
.dropdown-item {
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

/* Style for submenu */
.children {
  min-width: 200px;
  display: none;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-10px);
  transition: opacity 0.3s, visibility 0s linear 0.3s, transform 0.3s;
}

.dropdown-item:hover .children {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition-delay: 0s;
}

.custom-btn:enabled:hover, .custom-btn:enabled:focus, .custom-btn:enabled:active {
  color: #fff !important;
}

.image-container {
  background: #f5f5f5;
}

.page-container {
  background: var(--header-bg-color);
}

select.form-control {
  background-color: white;
  border: thin solid var(--main-color);
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select.form-control {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

app-base-header-item #custom__nav--children {
  position: absolute !important;
  z-index: 99 !important;
  box-shadow: 0 1px 9px -2px #000000bf;
  background: white;
}

.sidenav__link__parent:hover {
  background: var(--main-color) !important;
}

.sidenav__link__parent {
  padding: 0 !important;
}

a.menu__item__link {
  width: 100% !important;
}

.compare-item-hover {
  display: none !important;
}

.singleProduct .product-info .product-options .form-group .form-control, .singleProduct .product-info .product-options .form-group .product-price {
  width: fit-content !important;
  border-radius: 0.5rem;
}

.compare-page-head {
  background-color: white !important;
}

.compare-page-head .header-title {
  color: #000;
}

.header-buttons .custom-btn {
  background-color: gainsboro !important;
  color: #000 !important;
}

.compare-page-head .header-title:before {
  position: absolute;
  content: "";
  right: 0;
  bottom: -5px;
  width: 50%;
  height: 2px;
  background: var(--main-color);
}

@media (max-width: 767.99px) {
  .sortby .sort label select {
    height: 3.5rem;
    line-height: 3.5rem;
    border: 2px solid var(--main-color, #e6e6e6) !important;
    border-radius: 15px !important;
  }
}
@media (max-width: 991.98px) {
  .sortby .sort label {
    display: block;
    border-radius: 3rem;
    border: 1px solid #e6e6e6 !important;
  }
}
@media (max-width: 991.99px) {
  .header .main-header .cart-btn > span {
    background-color: var(--secondary-color) !important;
  }
}
@media (max-width: 767.99px) {
  .search-form .category-select {
    color: black !important;
  }

  app-base-header-item #custom__nav--children {
    position: unset !important;
  }
}
.widget .widget-head .widget-labels span {
  display: block;
  background-color: var(--main-color, #0099ff);
  border-radius: 15px !important;
  padding: 1px 9px;
  /* height: 3.3rem; */
  width: max-content;
  /* line-height: 3.3rem; */
  color: #fff;
  text-align: center;
  margin: -8px 18px 1rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

@media (max-width: 480px) {
  .search-form .category-select ~ i {
    left: 115px !important;
  }
}
@media screen and (max-width: 767.98px) {
  .sidenav__list .menu__item__link {
    padding: 5px 13px !important;
  }
}
.widget.list-view .widget-head {
  background-color: white !important;
}

.cart-links > a:hover, .cart-text-btn > button:hover, i.fa.fa-close:hover {
  color: #000 !important;
}

.order__placed__successfully {
  height: 70vh !important;
}

.widget.list-view .widget-content .widget-foot {
  text-align: center;
  display: block;
  width: 100%;
  position: absolute;
  bottom: 17px;
  right: 0;
}

@media (max-width: 992px) {
  .cart-btn.cart-wrapper:hover .cart-item-wrapper {
    display: none !important;
  }
}
.hidden {
  display: none;
}

.owl-dots {
  width: 100% !important;
  overflow-x: unset !important;
}

.item.active {
  height: 100%;
}

.singleProduct .gallery .main-image-product .image-wrapper img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.singleProduct .gallery .main-image-product .image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.singleProduct .gallery .imgSlide.thumbnails img {
  width: auto;
  height: 70%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.singleProduct .gallery .imgSlide.thumbnails a {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.singleProduct .gallery .imgSlide.thumbnails {
  border-radius: 7px;
  margin-top: 1rem;
}

.product-options {
  overflow-x: unset !important;
}

app-base-sub-category-slider {
  margin: unset !important;
}