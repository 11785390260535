@import '../node_modules/@bbndev/ngx-bbn-zvendo/assets/variables';

$main-color: var(--main-color ,#e6e6e6) !important;
$second-color: #0099ff;
$border-color:  #acacac;
$background-color: #F5F5F5;
$input-background-color: #ebebeb;
$hr-color: #adadad;
$top-header-background-color: #666666;
$forth-color: #01427f;
$greenColor: #368840;
$lightGray: rgba(0, 0, 0, .02);
$borderColor : rgba(0, 0, 0, .05);
$lightGrayDark: rgba(0, 0, 0, .1);
$textColor: #808080